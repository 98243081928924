import { isMobile } from '@/utils/common'
import { bannerImgs } from '../imgs-src/banner'
import { experienceImgHeight, experienceImgs } from '../imgs-src/experience'
import { newsImgs } from '../imgs-src/news'
import { socialValueImgs } from '../imgs-src/social-value'
import { techImgs } from '../imgs-src/tech'

export const bannerText = [
  [
    {
      number: '01',
      cn1: '探索新消费，',
      cn2: '创造极致生活体验。',
      en: 'KANYUN BRAND PROPOSITION - NEW CONSUMPTION',
      bg: bannerImgs.banner1,
      rotate: true,
    },
    {
      number: '01',
      cn1: '探索新消费，',
      cn2: '创造极致生活体验。',
      en: 'KANYUN BRAND PROPOSITION - NEW CONSUMPTION',
      bg: bannerImgs.banner2,
      rotate: false,
    },
  ],
  [
    // {
    //   number: '02',
    //   cn1: '引领教育科技，',
    //   cn2: '提供数字化产品服务。',
    //   en: 'KANYUN BRAND PROPOSITION - DIGITIZED EDUCATION',
    //   bg: bannerImgs.banner3,
    //   rotate: true,
    // },
    {
      number: '02',
      cn1: '引领教育科技，',
      cn2: '提供数字化产品服务。',
      en: 'KANYUN BRAND PROPOSITION - DIGITIZED EDUCATION',
      bg: bannerImgs.banner4,
      rotate: false,
    },
  ],
  [
    {
      number: '03',
      cn1: '创新数字出版，',
      cn2: '共享高品质数字内容。',
      en: 'KANYUN BRAND PROPOSITION - DIGITAL PUBLISHING',
      bg: bannerImgs.banner5,
      rotate: true,
    },
  ],
]

export const newsText = [
  {
    line1: '三里屯 The Cube',
    line2: '冬日之屋',
    line3: 'SKYPEOPLE 三里屯太古里品牌概念店',
    line4: '诚邀你来现场感受',
    line5: '多重城市视角与舒适得体的科技服装',
    date: '01/07',
    brand: 'SKYPEOPLE',
    year: '2022.9.30',
    img: newsImgs.news1,
    id: 0,
    link: 'https://mp.weixin.qq.com/s?__biz=MzkzODM4OTE1Nw==&mid=2247485197&idx=1&sn=93bdd87a7266f69cca71e8a0ed647c0e&chksm=c281bcc7f5f635d1da014c379cefd8e4967f41574ae2afacc5d6818a3e30284b022817216fe6&token=625105596&lang=zh_CN&st=BF1990D7DA24E1DB7882AFCF8453E6077104B8F765CF44011D17EA54BE4F69626ED047CE2DDCD7FC82B272B01E2B71B6E7E29A2FF7D80A74363350C76AC4F471E0C7DE81A115FF787309628A70DEBDE93E306A644EE29CDD8E6288A7534A947CA256493002BB2C1E155B13012BCECE088709FE3014C65EE99CB840508993B205B67EA866F8DC8F7A9E36F68706344983422CC95E0ADE2B1E1C69173633BC8B38283C9BD53389AB0C33DD8D1B23D1A93770C9EA397C1D729D289952061E4F5C5F2FC93F94BB204A6E83D60290BCF5D23766D12EFDD4D741952E39C9444CA40A672C0A4AAB74E9EB10B64030A410CBE0C52952F50B035874868F4847E7521BFF5D&vid=1688856016412715&cst=0DECC87C3FA30E23373F0B5E733BD40EB628ED9A2C9D57A32ABD7EA6FCC45719FE736519225ADD34BD9891A73DF28743&deviceid=5f7f1f06-73b9-4009-a359-88edafbb2be8&version=4.0.16.90619&platform=mac#rd',
  },
  {
    line1: '30000杯黑咖啡',
    line2: '北京挺酷',
    line3: 'The Black Spot是我们和大家的第一次见面',
    line4: '一个只卖黑咖啡的黑色咖啡车',
    line5: '一次小小的咖啡回归实验',
    date: '02/07',
    brand: 'GRID COFFEE',
    year: '2022.11.11',
    img: newsImgs.news2,
    id: 1,
    link: 'https://mp.weixin.qq.com/s/rTIKNSZ6xRczQg6aHb-_qg',
  },
  {
    line1: '飞象星球',
    line2: '亮相2023中国服贸会',
    line3: '飞象星球受邀作为中国「教育科技」代表',
    line4: '参展2023国际服务贸易交易会',
    line5: '发布教室大屏里首个人工智能互动体验',
    date: '03/07 ',
    brand: '飞象星球',
    year: '2023.09.02',
    img: newsImgs.news3,
    id: 2,
    link: 'https://mp.weixin.qq.com/s/Qv0_R9cKnKftHLky0PT_qQ',
  },
  {
    line1: '茉莉智慧开启',
    line2: '3.0智慧月子时代',
    line3: '携手福布斯中国发布《中国月子3.0时代白皮书》',
    line4: '以科技赋能和人文关怀为钥',
    line5: '开启新一代女性的“智”愈之旅',
    date: '04/07 ',
    brand: '茉莉智慧',
    year: '2023.05.14',
    img: newsImgs.news4,
    id: 3,
    link: 'https://mp.weixin.qq.com/s/ZcD5-BRwZNXqufcWjKXi8w',
  },
  {
    line1: '猿编程发布国内',
    line2: '首套计算思维课程',
    line3: '猿编程发布国内第一套',
    line4: '专为青少年研发设计的《计算思维课》',
    line5: '并创新推出“编程”与“计算思维”一起学的课程设置',
    date: '05/07 ',
    brand: '猿编程',
    year: '2023.5.22',
    img: newsImgs.news5,
    id: 4,
    link: 'https://tech.cnr.cn/techph/20230522/t20230522_526260281.shtml',
  },
  {
    line1: '海豚AI学',
    line2: '斩获德国iF设计奖',
    line3: '2023年，海豚AI学（原海豚自习App）',
    line4: '凭借“AI+互动动画”的突破式设计',
    line5: '斩获被誉为世界三大设计奖之一的「德国iF设计奖」',
    line6: '是国内唯一获奖的数字学习产品',
    date: '06/07 ',
    brand: '海豚AI学',
    year: '2023.4.19',
    img: newsImgs.news6,
    id: 5,
    link: 'http://m.caijing.com.cn/api/show?contentid=4931890',
  },
  {
    line1: '猿辅导推出',
    line2: '小猿学练机',
    line3: '小猿学练机创新还原线下手写学习模式',
    line4: '覆盖小初高100多个学习场景',
    line5: '开启真正个性化学习新时代',
    date: '07/07 ',
    brand: '猿辅导',
    year: '2023.5.30',
    img: newsImgs.news7,
    id: 6,
    link: 'https://my-h5news.app.xinhuanet.com/xhh-pc/article/?id=5f13d962-7dd5-4b84-b53a-8fbecabafa3f&timestamp=6062',
  },
]

export const experienceText = [
  {
    year: '2012',
    desc: '看云控股集团创立于2012年，旗下业务涵盖教育科技、新消费品、企业服务、数字出版等多个板块。集团致力于借助前沿科技创造极致用户体验，在各业务线运营着多个国内知名品牌，服务超过数亿的用户。',
    img: experienceImgs.kanyun,
    imgHeight: experienceImgHeight.kanyun,
    id: 0,
  },
  {
    year: '2014',
    desc: '小猿搜题是一款中小学家长和老师作业检查和辅导工具，海量题库全面覆盖小学、初中、高中，包括数学、语文、英语、物理、化学、生物在内的主要学科。遇到不会做的题，轻松一拍，就能秒出详细解析，更有老师视频讲解，帮你解决难题困扰！',
    img: experienceImgs.souti,
    imgHeight: experienceImgHeight.souti,
    id: 1,
  },
  {
    year: '2015',
    desc: '猿辅导在线教育正在为更多中国学生提供智能、便捷、有趣的在线学习体验。以科技推动大规模因材施教，致力于让中国的每一位学生，都能享有高品质、个性化的教育。',
    img: experienceImgs.fudao,
    imgHeight: experienceImgHeight.fudao,
    id: 2,
  },
  {
    year: '2017',
    desc: '小猿口算是一款免费帮助家长、老师减轻作业检查负担的学习工具类App，可通过拍照实现一秒检查小学作业，目前已全面覆盖小学阶段数学、语文、英语等各种题型。',
    img: experienceImgs.kousuan,
    imgHeight: experienceImgHeight.kousuan,
    id: 3,
  },
  {
    year: '2017',
    desc: '斑马App，儿童数字内容，专业、科技、高品质。以科技助力儿童成长解决方案，全面培养孩子的核心素养和全方位能力。',
    img: experienceImgs.banma,
    imgHeight: experienceImgHeight.banma,
    id: 4,
  },
  {
    year: '2017',
    desc: '猿编程创立于2017年10月24日，世界程序员日。从建立伊始，我们就已经预见科技未来的无限潜力。猿编程致力于少儿编程教育模式的探索与创新，帮助每个孩子掌握看见和创造未来的能力，让孩子用代码将自己心中的世界一点点构建出来。每一个正在发生的现在，都是未来的序章。',
    img: experienceImgs.biancheng,
    imgHeight: experienceImgHeight.biancheng,
    id: 5,
  },
  // {
  //   year: '2020',
  //   desc: '南瓜科学是专注于保护孩子好奇心的科学益智品牌。提供专业进阶的STEAM科学探究产品，和个性化的益智玩具，满足不同年龄段成长所需。品牌致力于以动手实践为核心，以智能科技为牵引，在玩乐中激发孩子自主探索力，解锁对世界的好奇。',
  //   img: experienceImgs.nangua,
  //   imgHeight: experienceImgHeight.nangua,
  //   id: 6,
  // },
  {
    year: '2021',
    desc: '飞象星球是一家专注于教育科技领域的技术公司。承载集团成立十年的教育科技研发积淀和市场化企业服务经验，飞象星球致力于为政府和学校提供体验极致的智能教育产品，让学习者共享科技带来的美好教育新体验。',
    img: experienceImgs.feixiang,
    imgHeight: experienceImgHeight.feixiang,
    id: 7,
  },
  {
    year: '2021',
    desc: 'Grid Coffee专注单一产地咖啡。我们寻找全世界最优质的咖啡豆，通过专业规则之下的反复打磨，让它变得可靠，并且负担得起，让更多人能享受得到。用好原料，做好基本款。',
    img: experienceImgs.coffe,
    imgHeight: experienceImgHeight.coffe,
    id: 8,
  },
  {
    year: '2021',
    desc: '茉莉智慧母婴护理中心，倡导「智能化时代·智慧坐月子」的品牌定位，秉承「科技创新 · 极致服务 · 爱与责任」的品牌理念，通过科技DNA赋能并注入人文关怀，以夯实月子服务为核心，提供「智慧护理 · 智慧康养 · 智慧育儿」的「智慧+」母婴照护解决方案。',
    img: experienceImgs.moli,
    imgHeight: experienceImgHeight.moli,
    id: 9,
  },
  {
    year: '2021',
    desc: '海豚AI学是一款全新的智能学习App，目前为1-9年级学生提供个性化的学习规划，通过「AI技术+互动动画」的创新结合，点燃孩子的学习兴趣，深入学科本质，提升知识素养，致力于培养孩子从被动学到主动学。',
    img: experienceImgs.haitun,
    imgHeight: experienceImgHeight.haitun,
    id: 10,
  },
  {
    year: '2022',
    desc: 'SKYPEOPLE 于 2022 年创立于北京，致力从用户出发，运用科技材料与创新设计，为中国城市人群设计高品质、高适配机能服装产品，为他们的工作生活赋能。',
    img: experienceImgs.sky,
    imgHeight: experienceImgHeight.sky,
    id: 11,
  },
  {
    year: '2023',
    desc: '小猿学练机成立于2023年5月，是创新结合墨水屏与人工智能技术在教育领域应用的新一代学习平板产品，具有不伤眼、能手写、智能化三大特点。自主研发的「以练促学」精准学系统，首次实现了学习内容、学习过程、学习数据的全面数字化，是一款离教育的本质更近，真正具有启发性、探究性、有效互动性的学习硬件产品。',
    img: experienceImgs.xuelianji,
    imgHeight: experienceImgHeight.xuelianji,
    id: 12,
  },
  {
    year: '2024',
    desc: 'Motiff妙多是一款“AI时代设计工具”，由看云软件研发，2024年6月于新加坡面向全球发布。作为一款企业级AI应用，Motiff妙多为UI/UX设计师、产品经理等用户人群打造专业设计工具，在国内首个UI大模型——Motiff妙多大模型的加持下，有效优化界面生产工作流，是全球领先的AI驱动协同设计软件。',
    img: experienceImgs.motiff,
    imgHeight: experienceImgHeight.motiff,
    id: 13,
  },
]

export const techText = [
  {
    id: 0,
    line1: '微软MSMarco',
    line2: '机器阅读理解排行榜',
    line3: '荣获全球第一',
    line4: '01/05',
    mobileLine1: '国际NLP大赛',
    mobileLine2: '微软机器阅读理解排行榜',
    mobileLine3: '全球第一',
    img: techImgs.img1,
    imgWidth: '48.54%',
    imgTop: '16%',
    imgRight: '9.01%',
  },
  {
    id: 1,
    line1: '斯坦福问答数据集',
    line2: '水平测试',
    line3: '荣获全球第一',
    line4: '02/05',
    mobileLine1: '国际NLP大赛',
    mobileLine2: '斯坦福问答数据集水平测试',
    mobileLine3: '全球第一',
    img: techImgs.img2,
    imgWidth: '48.23%',
    imgTop: '25.02%',
    imgRight: '12.4%',
  },
  {
    id: 2,
    line1: '出版《深度学习核心',
    line2: '技术与实践》，',
    line3: '斩获业内10万+好评',
    line4: '03/05',
    mobileLine1: ' 出版书籍',
    mobileLine2: '《深度学习核心技术与实践》',
    mobileLine3: '业内好评10万+',
    img: techImgs.img4,
    imgWidth: '39.53%',
    imgTop: '23.45%',
    imgRight: '13.38%',
  },
  {
    id: 3,
    line1: '十年百亿研发投入',
    line2: '多项自主研发',
    line3: '技术积累',
    line4: '04/05',
    mobileLine1: '十年百亿研发投入',
    mobileLine2: '自主研发人工智能领域',
    mobileLine3: '多项底层技术',
    img: techImgs.img3,
    imgWidth: '64.95%',
    imgTop: '25.35%',
    imgRight: '9.11%',
  },
]

if (!isMobile()) {
  techText.push({
    id: 4,
    line1: '集团',
    line2: '研究成果',
    line3: ' ',
    line4: '05/05',
    mobileLine1: '集团',
    mobileLine2: '研究成果',
    mobileLine3: '研究成果',
    img: techImgs.img5 as string,
    imgWidth: '54.17%',
    imgTop: '25.10%',
    imgRight: '10.89%',
  })
}

export const socialValueText = [
  {
    number: '01',
    date: 'NOV.23, 2022',
    location: 'BEIJING',
    line1: '和北京孩子同上一堂课：',
    line2: '科技赋能打破课后服务',
    line3: '时空限制',
    img: socialValueImgs.social1,
    link: 'https://mp.weixin.qq.com/s/b6h_7tdEyPKCzWD6wknheg',
  },
  {
    number: '02',
    date: 'NOV.23, 2022',
    location: 'BEIJING',
    line1: '在西藏、四川、云南等地区',
    line2: '孩子们可以永久免费观看',
    line3: '100节斑马阅读动画内容',
    img: socialValueImgs.social2,
    link: 'https://mp.weixin.qq.com/s/KajiL0I5Jnovqggd6I2NQQ',
  },
  {
    number: '03',
    date: 'NOV.23, 2022',
    location: 'BEIJING',
    line1: '“耳蜗宝宝”小叮当“听见”',
    line2: '新世界：斑马App自主学习',
    line3: '语言能力质变',
    img: socialValueImgs.social3,
    link: 'https://mp.weixin.qq.com/s/Blqpm_Ka0IR-bAMrAV0EMA',
  },
]

export const footerText = [
  [
    {
      text: '猿辅导',
      link: 'https://www.yuanfudao.com/',
    },
    {
      text: '小猿口算',
      link: 'https://xiaoyuankousuan.com/',
    },
    {
      text: '斑马',
      link: 'https://banmaapp.com/',
    },
    {
      text: '猿编程',
      link: 'https://www.ybccode.com/',
    },
    {
      text: '小猿搜题',
      link: 'https://www.yuansouti.com/',
    },
  ],
  [
    // {
    //   text: '南瓜科学',
    //   link: 'https://www.nanguakexue.com/',
    // },
    {
      text: '飞象星球',
      link: 'https://www.feixiangxingqiu.com/',
    },
    {
      text: 'GRID COFFEE',
      link: 'https://mp.weixin.qq.com/s/l-uvfRJhuObI7OkzUxUI6g',
    },
    {
      text: '茉莉智慧',
      link: 'https://www.moliyuezi.com/',
    },
    {
      text: '海豚AI学',
      link: 'https://www.haitunzixi.com/',
    },
    {
      text: 'SKYPEOPLE',
      link: 'https://mp.weixin.qq.com/s/2a4xraPYouur2ZKAPgbFYA',
    },
  ],
  [
    {
      text: '小猿学练机',
      link: 'https://xiaoyuanyingjian.com/#/',
    },
    {
      text: 'Motiff',
      link: 'https://motiff.com/?keyfrom=bd-pz-jrgw',
    },
    {
      text: '加入我们',
      link: 'https://app.mokahr.com/social-recruitment/fenbi/45505#/',
    },
    // {
    //   text: '校园招聘',
    //   link: 'https://hr.yuanfudao.com/#/',
    // },
  ],
]
